import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

import Arrow from '/public/assets/black-arrow.svg';

import getImgSrcWorkaround from '@/utils/images';
import { trackClick, getPath } from '../utils/trackCondition';

import { ArrowWrapper, Content, ImageWrapper, Name, Wrapper, ImageMask } from './styles';

export default function SmallCondition({ condition, sectionName }) {
  const { smallImage, name, product } = condition;
  const pathname = usePathname();
  const params = useSearchParams();
  const path = getPath({ condition, pathname, params });

  return (
    <Wrapper>
      <Link href={path} onClick={() => trackClick({ condition, sectionName, path })}>
        <ImageWrapper>
          <ImageMask />
          <Image
            src={getImgSrcWorkaround(smallImage?.url ?? smallImage?.data?.attributes?.url)}
            alt={
              smallImage?.alternativeText ??
              smallImage?.data?.attributes?.alternativeText ??
              name ??
              ''
            }
            width={280}
            height={192}
          />
        </ImageWrapper>
        {product?.data?.attributes ? (
          <Content>
            <Name>{name}</Name>
            <ArrowWrapper>
              <Arrow />
            </ArrowWrapper>
          </Content>
        ) : (
          <Content>
            <Name>{name}</Name>
          </Content>
        )}
      </Link>
    </Wrapper>
  );
}
