import { Container } from '@/components/ui/flexbox';

import Counter from './Counter';

import { Label, Wrapper } from './styles';
import Section from '@/components/ui/Section';

const CountUp = ({ content }) => {
  const { number, label } = content;

  if (!number) return null;

  return (
    <Section>
      <Container large>
        <Wrapper>
          <Counter number={number} />
          {label && <Label>{label}</Label>}
        </Wrapper>
      </Container>
    </Section>
  );
};

export default CountUp;
