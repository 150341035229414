import Image from 'next/image';

import { Box, Container, Content, Title } from './styles';

import getImgSrcWorkaround from '@/utils/images';
import theme from '@/utils/styles-variables';

const Card = ({ title, label, image }) => {
  return (
    <Box>
      <Container>
        <Image
          alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? title ?? ''}
          src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
          width={80}
          height={80}
          sizes={`(max-width: ${theme.breakpoints.max_sm}px) 100vw, ${theme.breakpoints.max_md}px 44vw, 405px`}
        />
        <Content>
          <Title>{title}</Title>
          <div>{label}</div>
        </Content>
      </Container>
    </Box>
  );
};

export default Card;
