import styled from 'styled-components';

import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';

export const Title = styled.h1`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  max-width: 640px;
`;
export const StyledSection = styled(Section)`
  clip-path: circle(farthest-side at 50% -10000px);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    clip-path: circle(farthest-side at 50% -4000px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    clip-path: circle(farthest-side at 50% -2500px);
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
