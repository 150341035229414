import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 16px;
  aspect-ratio: 5/4;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 779px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 100%;
  }
`;
