import classNames from 'classnames';
import { useCallback, useEffect, useId, useState } from 'react';

import FullWidthCondition from '../FullWidthCondition';

import useEmblaCarousel from 'embla-carousel-react';

import SvgArrow from '@/public/icons/arrow-slider.svg';

import { Slider, ArrowsWrapper, NextArrow, PrevArrow } from './styles';

//TODO: allow to use the smallConditions cards
const SliderConditions = ({ conditions, sectionName }) => {
  const sliderId = useId();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'false',
    duration: 20,
  });

  const isCurrentSlide = useCallback((index) => index === selectedIndex, [selectedIndex]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const onSelect = useCallback(() => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <>
      <Slider ref={emblaRef}>
        <ul
          className="embla__container"
          id={sliderId}
          aria-live="polite"
          aria-roledescription="carousel"
        >
          {conditions.map((slide, index) => (
            <li
              id={`${sliderId}-${index}`}
              aria-hidden={!isCurrentSlide(index)}
              aria-roledescription="slide"
              aria-label={`${index + 1} of ${conditions.length}`}
              key={slide.id}
              className={classNames({
                embla__slide: true,
                'slide-active': isCurrentSlide(index),
              })}
            >
              <FullWidthCondition condition={slide.attributes} sectionName={sectionName} />
            </li>
          ))}
        </ul>
      </Slider>
      <ArrowsWrapper>
        <PrevArrow onClick={scrollPrev} aria-controls={sliderId} aria-label="Previous Item">
          <SvgArrow aria-hidden={true} />
        </PrevArrow>
        <NextArrow onClick={scrollNext} aria-controls={sliderId} aria-label="Next Item">
          <SvgArrow aria-hidden={true} />
        </NextArrow>
      </ArrowsWrapper>
    </>
  );
};

export default SliderConditions;
