import Image from 'next/image';
import { usePathname, useSearchParams } from 'next/navigation';
import getImgSrcWorkaround from '@/utils/images';
import { getPath, trackClick } from '../utils/trackCondition';

import {
  Content,
  ImageWrapper,
  Name,
  Wrapper,
  Product,
  Button,
  GradiantTop,
  GradiantBottom,
} from './styles';

export default function FullWidthCondition({ condition, sectionName }) {
  const { fullImage, name, product } = condition;
  const pathname = usePathname();
  const params = useSearchParams();
  const path = getPath({ condition, pathname, params });

  return (
    <Wrapper
      href={path}
      onClick={() => trackClick({ condition, sectionName, buttonLabel: 'Learn more', path })}
    >
      <GradiantTop />
      <GradiantBottom />
      <Product>{product.data.attributes?.name}</Product>
      <ImageWrapper>
        <Image
          src={getImgSrcWorkaround(fullImage?.url ?? fullImage?.data?.attributes?.url)}
          alt={
            fullImage?.alternativeText ??
            fullImage?.data?.attributes?.alternativeText ??
            condition.name ??
            ''
          }
          fill
        />
      </ImageWrapper>
      <Content>
        <Name>{name}</Name>
        {product?.data?.attributes && <Button forwardedAs="span">Learn more</Button>}
      </Content>
    </Wrapper>
  );
}
