import { Container } from '@/components/ui/flexbox';

import Icon from './Icon';
import Image from './Image';

import { Grid, IconsList } from './styles';
import Section from '@/components/ui/Section';

const SideImageIcons = ({ content }) => {
  const { image, icon_list } = content;

  return (
    <Section>
      <Container large>
        <Grid>
          <Image image={image}></Image>
          <IconsList>
            {icon_list.map((icon) => (
              <Icon title={icon.title} label={icon.label} icon={icon.icon} key={icon.title} />
            ))}
          </IconsList>
        </Grid>
      </Container>
    </Section>
  );
};

export default SideImageIcons;
