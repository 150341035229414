import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
`;

export const Label = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
