import CountUp from 'react-countup';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Number } from './styles';

const Counter = ({ number }) => {
  const [isVisible, setIsVisible] = useState(false);

  const [reference, inView] = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (inView && !isVisible) {
      setIsVisible(true);
    }
  }, [inView, isVisible]);

  return (
    <Number ref={reference} data-testid="box">
      {isVisible ? <CountUp start={number - 1000} end={number} duration={3} /> : '--'}
    </Number>
  );
};

export default Counter;
