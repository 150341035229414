import { useContext } from 'react';
import { DraggableConditions } from '../Conditions';
import Form from '../Form';
import EligibilityClaim from './EligibilityClaim';

import { Title, Subtitle, StyledSection, StyledContainer } from './styles';

import ClientContext from '@/contexts/client.context';
import { Logos } from '@/app/components/shared/Logos/Logos';

const HIDE_ELIGIBILITY_CLAIM = true;

export default function Header({ logos, content, showSwordLogo }) {
  const { client } = useContext(ClientContext);

  return (
    <StyledSection colored lastColored>
      <StyledContainer>
        <Logos logos={logos} showSwordLogo={showSwordLogo || !logos} />
        <Title>{content.title}</Title>
        {content.subtitle && <Subtitle data-testid="header-subtitle">{content.subtitle}</Subtitle>}
        {!HIDE_ELIGIBILITY_CLAIM && content.eligibilityClaim && (
          <EligibilityClaim>{content.eligibilityClaim}</EligibilityClaim>
        )}
        {!client?.clientKey && <Form client={client} product={client?.product} />}
      </StyledContainer>
      <DraggableConditions conditions={content?.conditions?.data} sectionName="Header" />
    </StyledSection>
  );
}
