import styled from 'styled-components';

import { H2 } from '@/components/ui/Title';

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 40px;
  flex-wrap: wrap;
`;

export const Title = styled(H2)`
  padding-bottom: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 40px;
  }
`;
