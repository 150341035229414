import { useCallback, useEffect, useReducer, useRef } from 'react';
import classNames from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';

import useReducedMotion from '@/utils/useReducedMotion';

import { ListWrapper } from './styles';

import FullWidthCondition from '../FullWidthCondition';
import SmallCondition from '../SmallCondition';

function scrollerReducer(state, action) {
  switch (action.type) {
    case 'toggle_autoplay':
      return {
        ...state,
        autoplay: action.value,
      };
    case 'toggle_scrollable':
      return {
        ...state,
        scrollable: action.value,
        autoplay: action.value,
      };
    case 'set_visibility':
      return {
        ...state,
        slidesInViewport: action.value,
      };
    default:
      return state;
  }
}

const DraggableConditions = ({ conditions, fullCondition, sectionName }) => {
  const rafId = useRef(0);
  const windowWidth = useRef(0);

  const prefersReducedMotion = useReducedMotion();
  const [{ autoplay, scrollable, slidesInViewport }, dispatch] = useReducer(scrollerReducer, {
    autoplay: false,
    scrollable: false,
    slidesInViewport: [],
  });
  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    dragFree: true,
    loop: true,
    inViewThreshold: 1,
  });

  const updateAria = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    dispatch({
      type: 'set_visibility',
      value: emblaApi.slidesInView(),
    });
  }, [emblaApi]);

  const animate = useCallback(() => {
    if (!autoplay || !emblaApi || !rafId.current) return;

    const engine = emblaApi.internalEngine();

    if (!engine) return;

    engine.location.add(-1);
    engine.target.set(engine.location);
    engine.scrollLooper.loop(-1);
    engine.slideLooper.loop();
    engine.translate.to(engine.location);

    updateAria();

    rafId.current = requestAnimationFrame(animate);
  }, [autoplay, emblaApi, updateAria]);

  const stopAutoScroll = useCallback(() => {
    rafId.current = cancelAnimationFrame(rafId.current) || 0;
  }, []);

  const startAutoScroll = useCallback(() => {
    if (prefersReducedMotion || !autoplay) {
      stopAutoScroll();

      return;
    }

    // prevent duplicate rAFs
    if (rafId.current) {
      return;
    }

    rafId.current = requestAnimationFrame(animate);
  }, [autoplay, animate, prefersReducedMotion, stopAutoScroll]);

  const checkScroll = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    const totalWidth = Object.values(emblaApi.slideNodes()).reduce(
      (total, i) => total + i.offsetWidth,
      0,
    );
    const isScrollable = totalWidth >= window.innerWidth;

    dispatch({ type: 'toggle_scrollable', value: isScrollable });
    dispatch({
      type: 'toggle_autoplay',
      value: isScrollable && emblaApi.scrollSnapList().length > 2,
    });

    emblaApi.reInit({ active: isScrollable });
    startAutoScroll();
  }, [emblaApi, startAutoScroll]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    function onResize() {
      if (windowWidth.current === window.innerWidth) return;

      windowWidth.current = window.innerWidth;
      checkScroll();
    }

    checkScroll();

    emblaApi.on('pointerDown', stopAutoScroll);
    emblaApi.on('select', updateAria);

    window.addEventListener('resize', onResize);

    return () => {
      stopAutoScroll();
      window.removeEventListener('resize', onResize);
    };
  }, [emblaApi, checkScroll, stopAutoScroll, updateAria]);

  if (!conditions) return null;

  return (
    <ListWrapper
      onMouseEnter={stopAutoScroll}
      onMouseLeave={startAutoScroll}
      className={classNames({
        scrollable,
      })}
      ref={emblaRef}
      fullCondition={fullCondition}
    >
      <ul
        className="embla__container"
        aria-roledescription="carousel"
        aria-live={prefersReducedMotion ? 'polite' : 'off'}
      >
        {conditions.map((condition, index) => (
          <li
            key={condition.id}
            className="embla__slide"
            aria-roledescription="slide"
            aria-label={`${index + 1} of ${conditions.length}`}
            aria-hidden={slidesInViewport.indexOf(index) < 0}
          >
            {fullCondition ? (
              <FullWidthCondition condition={condition.attributes} sectionName={sectionName} />
            ) : (
              <SmallCondition condition={condition.attributes} sectionName={sectionName} />
            )}
          </li>
        ))}
      </ul>
    </ListWrapper>
  );
};
export default DraggableConditions;
