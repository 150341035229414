import styled from 'styled-components';

export const Box = styled.div`
  display: flex;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    flex: 1 1 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: calc(50% - 20px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
`;

export const Title = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;
