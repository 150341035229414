import { Box } from './styles';
import Image from 'next/image';

const Logo = ({ url, alt }) => {
  return (
    <Box>
      <Image src={url} alt={alt} width={124} height={24} />
    </Box>
  );
};

export default Logo;
