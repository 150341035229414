import Link from 'next/link';
import styled from 'styled-components';

import { TertiaryButton } from '@/components/core/Button';

export const Wrapper = styled(Link)`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  height: 100%;
`;

export const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 24px;
  z-index: 1;

  button {
    width: 100%;
    text-align: center;
  }
`;

export const Name = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #fff;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  img {
    transition: all 120ms ease-out;
    object-fit: cover;
  }

  ${Wrapper}:hover & {
    img {
      transform: scale(1.05);
    }
  }
`;

export const Product = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  font-family: ${(props) => props.theme.typography.family.title};
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const Button = styled(TertiaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.colors.grey[800]};
  background-color: ${(props) => props.theme.colors.white};
  border-color: ${(props) => props.theme.colors.white};

  transition: all 120ms ease-out;

  ${Wrapper}:hover & {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.grey[800]};
    border-color: ${(props) => props.theme.colors.grey[800]};
  }
`;

export const GradiantTop = styled.div`
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(31, 34, 44, 0.5) 0%, rgba(31, 34, 44, 0) 100%);
  height: 120px;
  top: 0;
  z-index: 0;
`;

export const GradiantBottom = styled.div`
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(31, 34, 44, 0.8) 0%, rgba(31, 34, 44, 0) 100%);
  height: 240px;
  bottom: 0;
  z-index: 0;
`;
