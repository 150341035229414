import styled from 'styled-components';

import { UpperText } from '@/components/ui/Title';

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Title = styled(UpperText)`
  font-family: ${(props) => props.theme.typography.family.title};
  padding-bottom: 40px;
  text-align: center;
  color: ${(props) => props.theme.colors.default};
`;
