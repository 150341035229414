import styled from 'styled-components';

export const Number = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  padding: 16px 56px;
  border-radius: 100px;
  color: ${(props) => props.theme.colors.primary.dark};
  background-color: ${(props) => props.theme.colors.primary.light};
  width: fit-content;
  margin: auto;
  font-variant-numeric: tabular-nums;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 8px 40px;
    font-size: 32px;
    line-height: 40px;
  }
`;
