import styled from 'styled-components';

export const Box = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  width: 220px;
  padding: 48px;
  text-align: center;
  border-radius: 8px;

  img {
    object-fit: contain;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 240px;
  }
`;
