import styled, { css } from 'styled-components';

export const arrowCSS = css`
  position: relative;
  cursor: pointer;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey.dark};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 24px;
`;

export const NextArrow = styled.button`
  ${arrowCSS};
`;

export const PrevArrow = styled.button`
  ${arrowCSS};

  & svg {
    transform: rotate(180deg);
  }
`;

export const Slider = styled.div`
  --item-width: 320px;
  cursor: grab;

  .embla__container {
    align-items: center;
    display: flex;
    touch-action: pan-y;
    gap: 24px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      gap: 6px;
    }
  }

  .embla__slide {
    height: 480px;
    flex: 0 0 auto;
    width: var(--item-width);
    position: relative;

    &.slide-active {
      pointer-events: auto;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      --item-width: 272px;
      height: 408px;
    }
  }
`;
