import { Container } from '@/components/ui/flexbox';

import Card from './Card';

import { Grid, Title } from './styles';
import Section from '@/components/ui/Section';

const ThreeColumnsCards = ({ content }) => {
  const { title, card } = content;

  return (
    <Section>
      <Container large>
        {title && <Title>{title}</Title>}
        <Grid>
          {card.map((item) => (
            <Card {...item} key={item.title} />
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default ThreeColumnsCards;
