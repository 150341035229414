import Image from 'next/image';

import { Wrapper } from './styles';

import getImgSrcWorkaround from '@/utils/images';

const ImageBox = ({ image }) => {
  return (
    <Wrapper>
      <Image
        alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? ''}
        fill
        sizes="(max-width: 768px) 100vw, 780px"
        src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
      />
    </Wrapper>
  );
};

export default ImageBox;
