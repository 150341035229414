import { Container } from '@/components/ui/flexbox';

import { ConditionsSlider } from '@/components/platform/Conditions';

import { Title } from './styles';
import Section from '@/components/ui/Section';

const Conditions = ({ content }) => {
  const { title, conditions } = content;

  if (!conditions?.data) return null;

  return (
    <Section>
      <Container large>
        <Title>{title}</Title>
        <ConditionsSlider conditions={conditions.data} sectionName={title} />
      </Container>
    </Section>
  );
};

export default Conditions;
