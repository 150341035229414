import styled from 'styled-components';

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: transform 120ms ease-in-out;
`;
export const ImageWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 192px;
  margin-bottom: 16px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 120ms ease-in-out;
  }
`;
export const ImageMask = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.grey.dark};
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 120ms ease-in-out;
  z-index: 2;
`;

export const Wrapper = styled.div`
  border-radius: 16px;
  padding: 8px 8px 16px;
  background-color: #fff;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    &:hover {
      ${ArrowWrapper} {
        transform: translateX(8px);
      }
      ${ImageWrapper} {
        img {
          transform: scale(1.05);
        }
      }
      ${ImageMask} {
        opacity: 0.05;
      }
    }
  }
`;

export const Content = styled.div`
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 0 16px;
`;

export const Name = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  overflow: hidden;
  text-wrap: normal;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
