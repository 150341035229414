import { Wrapper, Content, Title, Description } from './styles';

const Icon = ({ title, label }) => {
  return (
    <Wrapper>
      <Content>
        <Title>{title}</Title>
        <Description>{label}</Description>
      </Content>
    </Wrapper>
  );
};

export default Icon;
