import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components';

const StyledEligibilityClaim = styled.div`
  padding-top: 24px;
  color: ${({ theme }) => theme.colors.grey.default};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  max-width: 640px;

  p {
    &:not(:last-child) {
      padding-bottom: 16px;
    }

    a {
      text-decoration: underline;
    }

    em {
      font-style: italic;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    padding-top: 40px;
  }
`;

export default function EligibilityClaim({ children }) {
  return (
    <StyledEligibilityClaim data-testid="eligibilityClaim">
      <ReactMarkdown
        allowedElements={['p', 'strong', 'em', 'a', 'u']}
        rehypePlugins={[rehypeRaw]}
        unwrapDisallowed
      >
        {children}
      </ReactMarkdown>
    </StyledEligibilityClaim>
  );
}
