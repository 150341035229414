import { ACTIONS, BUTTON_TYPES, trackConditionSelected } from '@/utils/track';

export const trackClick = ({ condition, sectionName, buttonLabel, path }) => {
  trackConditionSelected({
    action: ACTIONS.REDIRECT_TO_PRODUCT_LP,
    buttonType: BUTTON_TYPES.NAVIGATION,
    buttonText: buttonLabel ?? condition.name,
    conditionName: condition.name,
    productName: condition?.product?.data?.attributes?.name,
    destination: path,
    buttonLocation: sectionName,
  });
};

export const getPath = ({ condition, pathname, params }) => {
  const { product } = condition;

  let path = product.data.attributes?.urlPath ?? '/' + product.data.attributes.productKey;

  if (pathname) {
    path = path + pathname;
  }

  if (params?.toString()) {
    path = path + '?' + params.toString();
  }

  return path;
};
