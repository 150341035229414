import styled from 'styled-components';

export const ListWrapper = styled.div`
  overflow: hidden;
  max-width: 100%;
  margin-top: 64px;

  .embla__container {
    margin: 0 -12px;
    align-items: center;
    display: flex;
    justify-content: center;
    touch-action: pan-y;
  }

  .embla__slide {
    flex: 0 0 auto;
    padding: 0 12px;
    width: ${(props) => (props.fullCondition ? '344px' : '304px')};

    @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
      width: ${(props) => (props.fullCondition ? '320px' : '290px')};
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  &.scrollable {
    cursor: grab;

    .embla__container {
      justify-content: flex-start;
    }
  }
`;
